<template>
  <b-card title="Up to past 6 months data">
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-card>
            <b-card-title>Daikin Warehouse</b-card-title>
            <b-table
              :per-page="perPage"
              :current-page="currentPage"
              :items="dealers"
              :fields="fieldsDaikin"
              :filter="search"
              :busy.sync="tableLoading"
              show-empty
            >
            <template #cell(acknowledgmentDaikin)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.acknowledgmentDaikin"
                  @click.prevent="detailStatus('Acknowledgment Daikin')"
                >
                  {{ row.item.acknowledgmentDaikin }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(approvalDaikin)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.approvalDaikin"
                  @click.prevent="detailStatus('Approval Daikin')"
                >
                  {{ row.item.approvalDaikin }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
            </b-table>
          </b-card>
        </b-col>

        <b-col cols="12" class="table-responsive">
          <b-card>
            <b-card-title>Sumitomo Warehouse</b-card-title>
            <b-table
              :per-page="perPage"
              :current-page="currentPage"
              :items="dealers"
              :fields="fieldsSumitomo"
              :filter="search"
              :busy.sync="tableLoading"
              show-empty
            >
            <template #cell(acknowledgmentSumitomo)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.acknowledgmentSumitomo"
                  @click.prevent="detailStatus('Acknowledgment Sumitomo')"
                >
                  {{ row.item.acknowledgmentSumitomo }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(approvalSumitomo)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.approvalSumitomo"
                  @click.prevent="detailStatus('Approval Sumitomo')"
                >
                  {{ row.item.approvalSumitomo }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import {userAccess} from '@/utils/utils'
  import lodash from "lodash";
  import vSelect from "vue-select";
  
  export default {
    data() {
      return {
        dealerChoice: "",
        dealers: [],
        form: {
          data: ["Dealer 1", "Dealer 2"],
        },
        //Daikin Warehouse field
        fieldsDaikin: [
          {
            key: "acknowledgmentDaikin",
            label: "Return Acknowledgment",
            filterable: true,
            sortable: false,
            class: "text-center",
          },
          {
            key: "approvalDaikin",
            label: "Return Approval",
            filterable: true,
            sortable: false,
            class: "text-center",
          },
        ],
        //Sumitomo Warehouse field
        fieldsSumitomo: [
          {
            key: "acknowledgmentSumitomo",
            label: "Return Acknowledgment",
            filterable: true,
            sortable: false,
            class: "text-center",
          },
          {
            key: "approvalSumitomo",
            label: "Return Approval",
            filterable: true,
            sortable: false,
            class: "text-center",
          },
        ],
        search: "",
        currentPage: 1,
        perPage: 5,
        totalRows: 1,
        searchByDo : "",
        tableLoading: true,
        toFilter: "",
        selectedFilter: "Model",
        selectedField: "model",
        type: "Set Up",
        typeOptions: ["Set Up", "Not Set Up", "Skip Scan"],
        typeDo: "Set Up",
        typeDoOptions: ["Set Up", "Not Set Up"],
        dealerCodeExist:''
      };
    },
    mounted() {
      // console.log('query',this.$route.query)
      this.$store.dispatch("dealer/getAllDealers");
      
      this.$store
        .dispatch('auth/fetchUser');
  
      this.$store.dispatch("dealer/getCurrentDealer").then((x) => {
        var payload = {};
        //console.info('x ',x);
        //console.info('dealer ',this.getDealer)

      this.getDashboardDealer()
        
      });
      this.checkParamDealerCode()
      
    },
    methods: {
      detailStatus(params) {
        if(params == 'Acknowledgment Daikin'){
          this.$router.push({
            path: "/return-delivery/daikin/",
            query: { status: "Waiting Acknowledgment" },
          });
        }
        if(params == 'Acknowledgment Sumitomo'){
          this.$router.push({
            path: "/return-delivery/sumitomo/",
            query: { status: "Waiting Acknowledgment" },
          });
        }
        if(params == 'Approval Daikin'){
          this.$router.push({
            path: "/return-delivery/daikin/",
            query: { status: "Waiting Approval" },
          });
        }
        if(params == 'Approval Sumitomo'){
          this.$router.push({
            path: "/return-delivery/sumitomo/",
            query: { status: "Waiting Approval" },
          });
        }
      },
      getDashboardDealer(Dcode) {
        let findDealer = this.dealer.find(({ dealerCode }) => dealerCode === Dcode);
        if (findDealer !== undefined) {
          this.dealerChoice = findDealer;
        }
        this.currentPage = 1;
        let payload = {
          itemModel: this.toFilter,
          sapDo: this.searchByDo, // Tambahkan filter sapDo
        };
        this.getDashboard(payload)
          .then((el) => {
            this.dealers = el;
            this.processMetadata(el);
          })
          .catch((err) => {
            console.log({ err });
          });
      },
      checkParamDealerCode () {
        let existingDealerCode = this.$route.query.dealerCode
        if(existingDealerCode) {
          this.getDashboardDealer(existingDealerCode)
        }else{
          return
        }
      },
      ...mapActions({
        getDealers: "dealer/getDealerWithQuery",
        getDashboard: "dashboard/getSalesAdmin",
        getDoCreated: "dashboard/getDoCreated",
        getDoProcessing: "dashboard/getDoProcessing",
        getDelivered: "dashboard/getDelivered",
        getWarranty: "dashboard/getWarranty",
      }),
      getAllInventories() {
        return this.dealers;
      },
      
      dropDownMenuClick(event) {
        this.selectedFilter = event.target.text;
        this.selectedField = event.target.getAttribute("field");
  
        //every change selected filter, will reset type to :
        this.typeDo = "Set Up";
        this.type = "Set Up";
      },
      clearFilter() {
        this.selectedField = "model";
        this.selectedFilter = "Model";
        this.toFilter = "";
        //
  
        this.getDashboardDealer()
      },
      getFilter() {
        if (this.selectedFilter === null) {
          return {};
        }
        let filter = {};
  
        let tempValue = this.toFilter;
        let tempFilter = this.selectedField;
  
        filter[tempFilter] = tempValue;
  
        return filter;
      },
      processMetadata(list) {
        this.totalRows = list.length
        this.tableLoading = false;
      },
    },
    computed: {
      dealerSelectAvailable() {
        var tempDealer = this.$store.getters["dealer/getCurrentDealers"];
        if (tempDealer!=null){
          return false
        }
        let result = userAccess("Dashboard Sales Admin", "dashboard_menu");
        console.log(result);
        // console.log("Delivery Orders Sumitomo",result)
        console.info('result permission ',result )
        if(!result){
          return false;
        }
        return result.view;
      },
      getDealer() {
        //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
        //kalau user bukan dealer, return undefined
        var tempDealer = this.$store.getters["dealer/getCurrentDealers"];
        //console.log('tempdealer ',tempDealer)
        if (tempDealer === null) {
          tempDealer = this.dealerChoice;
        }
        return tempDealer;
      },
      user() {
        //buat cek informasi akun yang sedang login
        var userData = ( this.$store.getters["auth/getActiveUser"].name)?
          this.$store.getters["auth/getActiveUser"]:
          {name:'nouser',roleRef:{name:'non'}}
        //console.info('userData ',userData)
        
        return userData
      },
      dealer() {
        //console.log('dealer ',this.$store.getters["dealer/getDealers"])
        return this.$store.getters["dealer/getDealers"].filter(
          (x) => x.status === ""
        );
      },
    },
    created() {
      document.title = "Dashboard| RSP";
      this.$store.dispatch("dealer/getAllDealers");
      
      // this.getDealers({
      //   page: this.currentPage,
      //   entry: this.perPage,
      // }).then((data) => this.processMetadata(data.metadata));
    },
  };
  </script>
  <style scoped>
  @media (min-width: 872px) {
    .hide-on-desktop { display: none !important; }
  }
  
  @media (max-width: 871px) {
    .hide-on-mobile { display: none !important; }
  }
  
  .filter-responsive {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .filter-responsive button {
    margin: 0 0 0 5px;
  }
  
  .filter-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    /* grid-template-columns: 20%; */
    column-gap: 10px;
  }
  
  
  @media screen and (max-width: 480px) {
    .filter-responsive {
      flex-direction: column;
      justify-content: none;
    }
  
    .filter-responsive button {
      margin: 0 0 0 0;
      width: 100px;
    }
  }
  </style>
  